<template>
    <Modal v-model="isShowAlertModal" title="親愛的夥伴您好" width="500px">
        <template v-slot:modal-content>
            <div class="alert-content">
                友邦人壽經代專區需透過 SSO 入口進入，請前往PKS
                站台並點擊經代專區連結進入（如下方示意圖）
            </div>
            <div class="icon alert-image">
                <img
                    src="../../assets/images/PKS-AIA_link_position.webp"
                    alt="系統畫面"
                />
            </div>
        </template>
        <template v-slot:modal-bottom>
            <a
                href="https://eip.leishan.com.tw:8080/Default.aspx?web=/"
                target="_blank"
            >
                <Button buttonStyle="dashboard" buttonWord="點我前往 PKS" />
            </a>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'

export default {
    name: 'ReminderModal',
    props: {
        modelValue: {
            type: Boolean,
            default: function () {
                return false
            }
        }
    },
    components: { Modal, Button },
    computed: {
        isShowAlertModal: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        }
    }
}
</script>
