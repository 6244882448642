<template>
    <Modal
        title="快速連結"
        v-model="isShowModal"
        :isFloat="$isMobile() ? false : true"
        class="quick-modal"
    >
        <template v-slot:modal-content>
            <TagMenu :options="tagOption" :isDashboard="true" v-model="mode" />
            <div
                v-if="mode === 'system'"
                class="dashboard-container system-container"
            >
                <a
                    v-for="(item, index) in systemLinkConfig"
                    :key="index"
                    :href="item?.link"
                    v-html="item.label"
                    target="_blank"
                    class="system-link-item"
                    @click="
                        (event) => {
                            if (!item.link) systemLinkHandler(event)
                        }
                    "
                />
            </div>
            <div v-if="mode === 'agent'" class="agent-container">
                <a
                    :href="agentLinkConfig.applicationLink"
                    target="_blank"
                    @click="
                        this.$setGaEvent(
                            `quickLinkApplicationUrl`,
                            'click-link'
                        )
                    "
                >
                    <Button
                        buttonStyle="dashboard"
                        buttonWord="保險公司經代「帳號/密碼 」申請表"
                /></a>
                <div class="dashboard-container link-container">
                    <ReminderModal v-model="isShowAlertModal" />
                    <div
                        class="agent-link-item dashboard-container"
                        v-for="(item, index) in agentLinkConfig.linkItems"
                        v-show="
                            (agentPage.current - 1) * 4 <= index &&
                            index < agentPage.current * 4
                        "
                        :key="index"
                    >
                        <div
                            class="icon"
                            :style="
                                item?.logo
                                    ? {
                                          backgroundImage: `url(${require(`@/assets/images/insurance_company_logo/${item?.logo}`)})`
                                      }
                                    : {}
                            "
                        />
                        <div class="info-container">
                            <p>{{ item?.label }}</p>
                            <a
                                target="_blank"
                                :class="{
                                    'has-link':
                                        item.link[agentLinkTitles[0].value]
                                }"
                                :href="item.link[agentLinkTitles[0].value]"
                                @click="
                                    (event) => {
                                        if (item.label === '友邦人壽') {
                                            this.isShowAlertModal = !this
                                                .isShowAlertModal
                                            event.preventDefault()
                                        }
                                        this.$setGaEvent(
                                            `quickLinkAgentLink_${agentLinkTitles[0].value}`,
                                            'click-link'
                                        )
                                    }
                                "
                                >經代專區</a
                            ><a
                                target="_blank"
                                :class="{
                                    'has-link':
                                        item.link[agentLinkTitles[1].value]
                                }"
                                :href="item.link[agentLinkTitles[1].value]"
                                @click="
                                    this.$setGaEvent(
                                        `quickLinkAgentLink_${agentLinkTitles[1].value}`,
                                        'click-link'
                                    )
                                "
                                >電子書</a
                            ><a
                                target="_blank"
                                :class="{
                                    'has-link':
                                        item.link[agentLinkTitles[2].value]
                                }"
                                :href="item.link[agentLinkTitles[2].value]"
                                @click="
                                    this.$setGaEvent(
                                        `quickLinkAgentLink_${agentLinkTitles[2].value}`,
                                        'click-link'
                                    )
                                "
                                >建議書</a
                            >
                        </div>
                    </div>
                    <div class="pagination-container">
                        <div
                            class="arrow-btn left"
                            :class="{ disabled: agentPage.current === 1 }"
                            @click="agentPreviousPage()"
                        >
                            <div class="icon" />
                        </div>
                        {{ agentPage.current + '/' + agentPage.total }}
                        <div
                            class="arrow-btn right"
                            :class="{
                                disabled: agentPage.current === agentPage.total
                            }"
                            @click="agentNextPage()"
                        >
                            <div class="icon" />
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:modal-bottom> </template>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import TagMenu from '@/components/TagMenu.vue'
import ReminderModal from './home/ReminderModal.vue'
import {
    systemQuickLinkConfig,
    agentQuickLinkConfig
} from '@/assets/javascripts/home/setting'
import Button from '@/components/Button.vue'

export default {
    name: 'QuickLinkModal',
    emits: ['update:modelValue'],
    components: {
        Modal,
        TagMenu,
        Button,
        ReminderModal
    },
    props: {
        modelValue: {
            type: Boolean,
            default: function () {
                return false
            }
        }
    },
    methods: {
        agentPreviousPage: function () {
            if (this.agentPage.current !== 1) this.agentCurrentPage -= 1
        },
        agentNextPage: function () {
            if (this.agentPage.current < this.agentPage.total)
                this.agentCurrentPage += 1
        },
        systemLinkHandler: function (event) {
            this.$setGaEvent(`quickLinkLsSystemUrl`, 'click-link')
            event.preventDefault()
        }
    },
    computed: {
        isShowModal: {
            get() {
                return this.modelValue
            },
            set(val) {
                if (!val) {
                    setTimeout(() => {
                        this.mode = 'agent'
                    }, 500)
                }
                this.$emit('update:modelValue', val)
            }
        },
        systemLinkConfig: function () {
            return systemQuickLinkConfig
        },
        agentLinkConfig: function () {
            return agentQuickLinkConfig
        },
        agentLinkTitles: function () {
            return [
                { label: '經代專區', value: 'agent' },
                { label: '電子書', value: 'eBook' },
                { label: '建議書', value: 'proposal' }
            ]
        },
        tagOption: function () {
            return [
                { label: '經代專區', value: 'agent' },
                { label: '磊山系統', value: 'system' }
            ]
        },
        agentPage: function () {
            const itemCount = this.agentLinkConfig?.linkItems.length
            let addCount = itemCount % 4 == 0 ? 0 : 1
            return {
                total:
                    parseInt(this.agentLinkConfig?.linkItems.length / 4) +
                    addCount,
                current: this.agentCurrentPage
            }
        }
    },
    data() {
        return {
            mode: 'agent',
            agentCurrentPage: 1,
            isShowAlertModal: false
        }
    },
    mounted() {}
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/icon.scss';

.quick-modal {
    > :deep(.modal-background) {
        > .modal-size:first-child {
            max-width: 340px;
            width: 340px;
            max-height: calc(100vh - 100px);
            background: $primary-white;
            @media screen and (max-width: 576px) {
                width: 100%;
            }
            @media (min-width: 577px) and (max-width: 1280px) {
                max-height: calc(100vh - 70px);
            }
        }
    }
}

// :deep(.modal-size) {
//     background: $primary-white;
//     max-height: calc(100vh - 100px);
//     @media (min-width: 577px) and (max-width: 1280px) {
//         max-height: calc(100vh - 70px);
//     }
// }

:deep(.modal-title),
:deep(.modal-bottom) {
    background: $primary-white;
    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: $dashboard-gradient-lightest;
        top: 0;
        left: 0;
        z-index: -1;
        transform: translateZ(101px);
    }
}
:deep(.modal-title) {
    padding-bottom: 10px;
    .title {
        margin-bottom: 0px;
    }
}

:deep(.modal-content) {
    background: $dashboard-gradient-lightest;
    .alert-content {
        color: $sixth-black;
    }
    .alert-image {
        margin-top: 10px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

:deep(.modal-bottom) {
    padding: 20px;
    box-shadow: none;
}

:deep(.title) {
    > div {
        font-size: 18px;
        @media screen and (max-width: 576px) {
            font-size: 20px;
        }
    }
}

:deep(.scroll-wrapper) {
    margin-bottom: 10px;
}

.dashboard-container {
    box-shadow: none;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    @media screen and (max-width: 350px) {
        padding: 10px 5px;
    }
    &.system-container {
        justify-content: flex-start;
        row-gap: 10px;
        column-gap: 15px;
    }
    .system-link-item {
        width: 80px;
        height: 80px;
        border-radius: 10px;
        font-size: 15px;
        color: $primary-white;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: pre-line;
        text-align: center;
        &:nth-of-type(8n + 1) {
            background: $fifth-red;
        }
        &:nth-of-type(8n + 2) {
            background: $primary-orange;
        }
        &:nth-of-type(8n + 3) {
            background: $secondary-yellow;
        }
        &:nth-of-type(8n + 4) {
            background: $secondary-green;
        }
        &:nth-of-type(8n + 5) {
            background: $third-green;
        }
        &:nth-of-type(8n + 6) {
            background: $sixth-blue;
        }
        &:nth-of-type(8n + 7) {
            background: $primary-purple;
        }
        &:nth-of-type(8n + 8) {
            background: $primary-pink;
        }
    }
}

.agent-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    > a {
        width: 100%;
        :deep(.button) {
            width: 100%;
            margin-right: 0;
            @media screen and (max-width: 350px) {
                .button-word {
                    font-size: 12px;
                }
            }
        }
    }

    .dashboard-container {
        margin-top: 10px;
        &.link-container {
            min-height: 360px;
            padding: 0 10px 60px;
            display: block;
            position: relative;
            width: calc(100% - 20px);
        }
        .agent-link-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: nowrap;
            background-color: $primary-grey;
            height: 60px;

            .icon {
                box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
                background-color: $primary-white;
                border-radius: 10px;
                width: 60px;
                height: 60px;
                margin-right: 15px;
                @media screen and (max-width: 350px) {
                    margin-right: 10px;
                }
            }
            .info-container {
                width: 170px;
                p {
                    font-size: 14px;
                    margin: 0;
                }
                a {
                    cursor: not-allowed;
                    font-size: 12px;
                    color: $ninth-grey;

                    &:not(:last-child) {
                        padding-right: 10px;
                        border-right: 1px solid $secondary-grey;
                    }
                    &:not(:first-of-type) {
                        padding-left: 10px;
                    }

                    &.has-link {
                        color: $fourth-blue;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.pagination-container {
    display: flex;
    height: 30px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    .arrow-btn {
        background: $dashboard-gradient;
        border-radius: 50%;
        margin: 0 30px;
        cursor: pointer;
        .icon {
            width: 30px;
            height: 30px;
            @include agent_arrow($primary-white);
        }
        &.right {
            transform: rotate(180deg);
        }
        &.disabled {
            opacity: 1;
            cursor: not-allowed;
            background: none;
            .icon {
                @include agent_arrow($ninth-grey);
            }
        }
    }
}
</style>
